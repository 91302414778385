import {_, Component, React, className} from 'components'; //eslint-disable-line
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { Popup } from 'henrybuilt-react-library';
import Page from './page/page';
import LandingPage from './landing-page/landing-page';
import Navigation from './navigation/navigation';
import NotFound from './page/not-found/not-found';
import SubscribeForm from 'components/app/subscribe-form/subscribe-form';
import InquiryForm from 'components/app/inquiry-form/inquiry-form';
import RegisterEmployee from './register-employee/register-employee';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import './app.scss';
import 'styles/form.scss';

var parsedQueryString = queryString.parse(window.location.search) || {};

class App extends Component {
  constructor(props) {
    super(props);

    this.changeHandlers = {};

    var hasSeenCVMessage = true; //Cookies.get('hasSeenCVMessage2');

    this.state = {
      showingNavigation: false,
      inquiryFormIsVisible: false,
      inquiryFormProps: {},
      contactFormType: 'callOrEmail',
      contactReason: 'project',
      cvPopupIsVisible: !hasSeenCVMessage,
      subscribePopupIsVisible: false,
      subscribeFormHasReloaded: false,
      inquiryPopupIsVisible: false
    };

    if (parsedQueryString.showContact === 'true') {
      this.state.showingNavigation = true;
      this.state.inquiryFormIsVisible = true;
    }

    this.props.history.listen(() =>  {
      this.setState({showingNavigation: false});
    });

    this.bind(['hideNavigation', 'toggleNavigation', 'handleNavigationToggleInquiryForm', 'showInquiryForm', 'considerShowingSubscribePopup', 'handleSubscribeFormLoad', 'considerShowingInquiryPopup', 'setContactFormType', 'setContactFormReason']);

    this.Navigation = (props) => (
      <Navigation
        {...props}
        {...this.state.inquiryFormProps}
        isVisible={this.state.showingNavigation}
        setContactFormType={this.setContactFormType}
        setContactFormReason={this.setContactFormReason}
        contactFormType={this.state.contactFormType}
        contactReason={this.state.contactReason}
        inquiryFormIsVisible={this.state.inquiryFormIsVisible}
        toggleNavigation={this.toggleNavigation}
        showInquiryForm={this.showInquiryForm}
        on={{
          toggleInquiryForm: this.handleNavigationToggleInquiryForm
        }}
      />
    );

    this.setState({subscribePopupIsVisible: true});

    this.LandingPage = (props) => (
      <LandingPage
        {...props}
        isRemodelistaVisitor={this.isRemodelistaVisitor}
        showInquiryForm={this.showInquiryForm}
        considerShowingInquiryPopup={this.considerShowingInquiryPopup}
      />
    );

    this.RegisterEmployee = (props) => (
      <RegisterEmployee
        {...props}
      />
    );

    this.Page = (props) => <Page showInquiryForm={this.showInquiryForm} scrollToSection={parsedQueryString.scrollToSection} considerShowingSubscribePopup={this.considerShowingSubscribePopup} considerShowingInquiryPopup={this.considerShowingInquiryPopup} {...props}/>;

    this.App = () => (
      <div {...className(['app app-sans-serif theme-light', this.state.showingNavigation && 'showing-navigation'])}>
        <Switch>
          <Route path='/page/:key' component={this.Navigation}/>
          <Route component={this.Navigation}/>
        </Switch>
        <div className='app-content' onClick={this.hideNavigation}>
          <Switch>
            <Route exact path='/' render={this.LandingPage} />
            <Route exact path='/page/register-employee' render={this.RegisterEmployee} />
            <Route path='/page/:key' component={this.Page} />
            <Route component={NotFound} />
          </Switch>
        </div>
        {this.state.cvPopupIsVisible && (
          <Popup type='fitted' onClose={this.handleCVPopupClose}>
            <div class="hb-cv-message">
              We're here... designing, planning, and happy to talk.
              <br /><br />
              <span class="cv-smaller">And we wish you a good day.</span>
              <br /><br />
              <div class="cv-icons">
                <a href="/page/showrooms">
                  <img alt='' src="https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/images/icons/phone.png"/>
                </a>
                <a href="mailto:inquiries@spacetheory.com">
                  <img alt='' src="https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/images/icons/mail.png"/>
                </a>
              </div>
            </div>
          </Popup>
        )}
        {this.state.subscribePopupIsVisible && (
          <Popup type='corner' position='left' onClose={this.handleSubscribePopupClose}>
            <div className='subscribe-popup'>
              <div className='subscribe-popup-tagline'>Get Inspired</div>
              <div className='subscribe-popup-message'>Sign up for our newsletter.</div>
              <div className='subscribe-form-container'>
                <iframe src='/subscribe-form' title='subscribe-form' onLoad={this.handleSubscribeFormLoad}></iframe>
              </div>
            </div>
          </Popup>
        )}
        {this.state.inquiryPopupIsVisible && (
          <Popup type='fitted' onClose={this.handleInquiryPopupClose}>
            <div className='inquiry-popup'>
              <div className='inquiry-popup-tagline'>Wondering what the next steps are?</div>
              <div className='inquiry-popup-message'>Discuss your project with one of our experts.</div>
              <div className='inquiry-popup-button button' onClick={this.handleInquiryPopupButtonClick}>Get in Touch</div>
            </div>
          </Popup>
        )}
      </div>
    );
  }

  get isRemodelistaVisitor() {
    var remodelistaCampaigns = [
      'remodelista_oct_2020',
      'remodelista_nov_2020'
    ];

    return Cookies.get('isRemodelistaVisitor') || _.includes(remodelistaCampaigns, parsedQueryString.utm_campaign);
  }

  get shouldShowInquiryForm() {
    return _.get(parsedQueryString, 'showInquiryForm', false);
  }

  componentDidMount() {
    if (this.isRemodelistaVisitor && !Cookies.get('isRemodelistaVisitor')) {
      Cookies.set('isRemodelistaVisitor', 'true', {expires: 7});
    }

    if (!this.state.inquiryFormIsVisible && this.shouldShowInquiryForm) this.showInquiryForm();
  }

  componentWillUpdate(_nextProps, nextState) {
    document.body.classList.toggle('no-scroll-mobile', nextState.showingNavigation);
  }

  considerShowingSubscribePopup({pageKey}) {
    var subscribePopupCookie = Cookies.getJSON('st_subscribe_popup');

    if (subscribePopupCookie) {
      var pagesVisited = _.get(subscribePopupCookie, 'pagesVisited', []);
      var userAction = _.get(subscribePopupCookie, 'userAction');

      if (pagesVisited.length < 3 && !_.includes(pagesVisited, pageKey)) {
        pagesVisited = [pageKey, ...pagesVisited];

        Cookies.set('st_subscribe_popup', {pagesVisited, userAction});
      }

      var notOnLandingPage = pageKey !== 'landing';
      var notOnSubscribePage = pageKey !== 'subscribe';

      var hasViewedEnoughPages = pagesVisited.length >= 3;
      var hasNotClosedPopup = typeof userAction === 'undefined';
      var inquiryPopupIsNotVisible = this.state.inquiryPopupIsVisible === false;

      if (hasViewedEnoughPages && hasNotClosedPopup && notOnLandingPage && inquiryPopupIsNotVisible && notOnSubscribePage) {
        setTimeout(() => this.setState({subscribePopupIsVisible: true}), 3000);
      }
      else {
        if (this.state.subscribePopupIsVisible === true) {
          this.setState({subscribePopupIsVisible: false});
        }
      }
    }
    else {
      Cookies.set('st_subscribe_popup', {pagesVisited: [pageKey], userAction});
    }
  }

  considerShowingInquiryPopup({pageKey}) {
    var inquiryPopupCookie = Cookies.getJSON('st_inquiry_popup');

    if (inquiryPopupCookie) {
      var pagesVisited = _.get(inquiryPopupCookie, 'pagesVisited', []);
      var userAction = _.get(inquiryPopupCookie, 'userAction');

      if (pagesVisited.length < 2 && !_.includes(pagesVisited, pageKey)) {
        pagesVisited = [pageKey, ...pagesVisited];

        Cookies.set('st_inquiry_popup', {pagesVisited, userAction});
      }

      var notOnLandingPage = pageKey !== 'landing';
      var notOnSubscribePage = pageKey !== 'subscribe';

      var hasViewedEnoughPages = pagesVisited.length >= 2;
      var hasNotClosedPopup = typeof userAction === 'undefined';
      var subscribePopupIsNotVisible = this.state.subscribePopupIsVisible === false;

      if (hasViewedEnoughPages && hasNotClosedPopup && notOnLandingPage && subscribePopupIsNotVisible && notOnSubscribePage) {
        setTimeout(() => this.setState({inquiryPopupIsVisible: true}), 3000);
      }
      else {
        if (this.state.inquiryPopupIsVisible === true) {
          this.setState({inquiryPopupIsVisible: false});
        }
      }
    }
    else {
      Cookies.set('st_inquiry_popup', {pagesVisited: [pageKey], userAction});
    }
  }

  handleSubscribeFormLoad() {
    if (this.state.subscribeFormHasReloaded) { // HINT iframe reloads after submit
      this.setTimeout(() => this.handleSubmitSubscribePopupSubmit(), 2000);
    }
    else {
      this.setState({subscribeFormHasReloaded: true});
    }
  }

  handleCVPopupClose = () => {
    this.setState({cvPopupIsVisible: false});

    Cookies.set('hasSeenCVMessage2', true);
  }

  handleSubscribePopupClose = () => {
    this.setState({subscribePopupIsVisible: false});

    var {pagesVisited} = Cookies.getJSON('st_subscribe_popup');

    Cookies.set('st_subscribe_popup', {userAction: 'close', pagesVisited});
  }

  handleSubmitSubscribePopupSubmit = () => {
    this.setState({subscribePopupIsVisible: false});

    var {pagesVisited} = Cookies.getJSON('st_subscribe_popup');

    Cookies.set('st_subscribe_popup', {userAction: 'submit', pagesVisited});
  }

  handleInquiryPopupClose = () => {
    this.setState({inquiryPopupIsVisible: false});

    var {pagesVisited} = Cookies.getJSON('st_inquiry_popup');

    Cookies.set('st_inquiry_popup', {userAction: 'close', pagesVisited});
  }

  handleInquiryPopupButtonClick = () => {
    this.setState({inquiryPopupIsVisible: false});

    this.setContactFormType({value: 'callOrEmail'});

    this.showInquiryForm();

    var {pagesVisited} = Cookies.getJSON('st_inquiry_popup');

    Cookies.set('st_inquiry_popup', {userAction: 'buttonClick', pagesVisited});
  }

  setContactFormType({value}) {
    this.setState({contactFormType: value});
  }

  setContactFormReason ({value}) {
    this.setState({contactReason: value});
  }

  hideNavigation() {
    this.toggleNavigation(false);
  }

  toggleNavigation(showingNavigation) {
    if (showingNavigation === undefined) showingNavigation = !this.state.showingNavigation;

    if (showingNavigation !== this.state.showingNavigation) {
      this.setState({showingNavigation, inquiryFormIsVisible: false});
    }
  }

  showInquiryForm(inquiryFormProps) {
    var contactFormType = _.get(inquiryFormProps, 'contactFormType', 'callOrEmail');
    var contactReason = _.get(inquiryFormProps, 'contactReason', 'project');

    this.setState({showingNavigation: true, inquiryFormIsVisible: true, contactFormType, contactReason});
  }

  handleMenuButtonClick(event) {
    var {showingNavigation} = this.state;

    if (!showingNavigation || event.target.className === 'navigation-tray') {
      this.toggleNavigation();
    }
  }

  handleNavigationToggleInquiryForm({inquiryFormIsVisible}) {
    this.setState({inquiryFormIsVisible});
  }

  render() {
    var urlRedirectPaths = {
      '/page/materials': '/page/materials-and-finishes',
      '/page/showrooms': '/page/visit-a-showroom',
      '/page/awards-press': '/page/awards',
    };

    return urlRedirectPaths[window.location.pathname] ? (
      <Redirect push to={urlRedirectPaths[window.location.pathname]} />
    ) : (
      <Switch>
        <Route path='/subscribe-form' component={SubscribeForm} />
        <Route path='/inquiry-form' component={InquiryForm} />
        <Route component={this.App}/>
      </Switch>
    );
  }
}

//TODO not sure withRouter is necessary here
export default withRouter(App);
export {App};
