import {_, Component, React, PropTypes} from 'components'; //eslint-disable-line
import Cookies from 'js-cookie';
import DocumentMeta from 'react-document-meta';
import './page.scss';
import NotFound from './not-found/not-found';
import Section from './section/section';
import pages from 'pages';

export default class Page extends Component {
  constructor(props) {
    super(props);

    this.pageSectionsRef = React.createRef();
  }

  componentDidMount() {
    var page = this.props.page || pages.index[this.key];

    this.props.considerShowingSubscribePopup({pageKey: page.key});
    this.props.considerShowingInquiryPopup({pageKey: page.key});

    this.considerAddingStructuredData(page);

    setTimeout(() => {
      if (this.props.scrollToSection) {
        var section = document.getElementById(`section-${this.props.scrollToSection}`);

        if (section) section.scrollIntoView({behavior: 'smooth'});
      }
    });
  }

  considerAddingStructuredData = (page) => {
    if (page.structuredData) {
      var script = document.createElement('script');

      script.setAttribute('type', 'application/ld+json');
      script.innerHTML = JSON.stringify(page.structuredData);

      document.querySelector('head').appendChild(script);
    }
  }

  // istanbul ignore next
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.pageSectionsRef.current) {
        this.pageSectionsRef.current.scrollTop = 0;
        this.pageSectionsRef.current.scrollLeft = 0;
      }

      this.props.considerShowingSubscribePopup({pageKey: this.key});
      this.props.considerShowingInquiryPopup({pageKey: this.key});
    }
  }

  render() {
    var page = this.props.page || pages.index[this.key];

    var forwardingUrlMap = {
      '/page/press': '/page/awards-press',
      '/page/awards': '/page/awards-press',
      '/page/basic-components': '/page/storage-components',
      '/page/products-details': '/page/storage-components',
      '/page/working-with-us': '/page/our-process',
      '/page/showrooms': '/',
      '/page/layout-and-considerations': '/page/design-guide',
      '/page/uniquely-functional': '/page/a-complete-system',
      '/page/simple': '/page/a-complete-system',
      '/page/get-started': '/page/our-process',
      '/page/pricing': '/page/pricing-with-examples',
    };

    var {origin, pathname} = window.location;

    if (!page) {
      window.location.href = forwardingUrlMap[pathname] || '/';
    }

    return page ? (
      <DocumentMeta
        title={`${page.title} - Space Theory`}
        description={`We believe in creating a new level of relaxed order, quality and pleasure while cooking, socializing and just...living. And we want to make it easier to achieve in your own home. To give you this experience, we've designed a unique - and uniquely functional - set of products that gives you a complete system, functional innovation, and premium quality.`}
        canonical={`${_.replace(origin, 'www.', '')}/page/${page.key}`}
        meta={{...!!page.doNotIndex && {name: {robots: 'noindex'}}}}
        extend
      >
        <div className='app-page'>
          <div className='page-sections' ref={this.pageSectionsRef}>
            {_.map(page.sections, (section, c) => <Section
              key={`${page.title}-${c}`}
              section={section}
              page={page}
              pageSectionsRef={this.pageSectionsRef}
              showInquiryForm={this.props.showInquiryForm}
            />)}
            <Footer environment={process.env.REACT_APP_IN_STAGING ? 'staging' : process.env.NODE_ENV}/>
          </div>
        </div>
      </DocumentMeta>
    ) : (
      <NotFound />
    );
  }

  get key() {
    return this.props.match.params.key || 'home';
  }

  static propTypes = {
    match: PropTypes.object.isRequired
  }
}

class Footer extends Component {
  state = {trackingAccepted: Cookies.get('gdprTrackingPermissionStatus') === 'opted-in'}

  toggleTrackingAccepted = () => {
    if (Cookies.get('gdprTrackingPermissionStatus') === 'opted-out') {
      this.setState({trackingAccepted: true});

      Cookies.set('gdprTrackingPermissionStatus', 'opted-in');
    }
    else {
      this.setState({trackingAccepted: false});

      Cookies.set('gdprTrackingPermissionStatus', 'opted-out');
    }
  }

  render() {
    return (
      <div className='footer' >
        <div className="careers-link"><a href="/page/careers">Careers</a></div>
        <div className="private-policy-link"><a target="_blank" rel='noopener noreferrer' href="https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/files/legal/henrybuilt/privacy.pdf">Privacy Policy</a></div>
        <div className='tracking-options' onClick={this.toggleTrackingAccepted}>opt {this.state.trackingAccepted ? 'out of' : 'in to'} cookie use</div>
        <div className="sf-credit">Site by <a href="https://www.symbolicframeworks.com" target="_blank" rel="noopener noreferrer">Symbolic Frameworks</a></div>
      </div>
    );
  }
}
