import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from 'components/app/app';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import FastClick from 'fastclick';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import Cookies from 'js-cookie';
import 'henrybuilt-js-library';

// istanbul ignore file

if (_.get(window, 'location.pathname') === '/design-engine') {
  window.location.replace('https://henrybuilt.com/design-engine');
}
else {
  const history = createBrowserHistory();

  //TODO this cookie is not properly set since it would come from another domain
  var isEmployee = Cookies.get('userIsEmployee');

  if (process.env.NODE_ENV === 'production' && isEmployee !== 'true') {
    history.listen((location) => {
      if (window.ga) {
        window.ga('set', 'page', location.pathname + location.search);
        window.ga('send', 'pageview');
      }
    });
  }

  FastClick.attach(document.body);

  ReactDOM.render((
    <DocumentTitle title='Space Theory'>
      <Router history={history}>
        <App />
      </Router>
    </DocumentTitle>
  ), document.getElementById('root'));
}

serviceWorker.unregister();
