import {_, Component, React, className} from 'components'; //eslint-disable-line
import './slideshow.scss';
import PropTypes from 'prop-types';

// @FB
// slideshow component creates slideshow template
// Related: Grid

class Slideshow extends Component {
  static propTypes = {
    media: PropTypes.array,
  }

  static defaultProps = {
    media: []
  }

  state = {
    index: 0,
    isFullscreen: false
  };

  constructor(props) {
    super(props);

    this.bind(['toggleFullscreen', 'prev', 'next', 'handleDotClick', 'handleKeyDown']);

    this.addDomEventListener({to: document, on: 'keydown', handler: this.handleKeyDown});
  }

  toggleFullscreen() {
    this.setState({isFullscreen: !this.state.isFullscreen});
  }

  prev() {
    var {media} = this.props, {index} = this.state;

    this.setState({index: index === 0 ? media.length - 1 : index - 1});
  }

  next() {
    var {media} = this.props, {index} = this.state;

    this.setState({index: index === media.length - 1 ? 0 : index + 1});
  }

  handleDotClick(event) {
      this.setState({index: parseInt(event.target.dataset.index)});
  }

  handleKeyDown(event) {
    if (event.keyCode === 38 || event.keyCode === 37) { //event.keyCode === 38) {
      this.prev();

      event.preventDefault();
    }
    else if (event.keyCode === 40|| event.keyCode === 39) {
      this.next();

      event.preventDefault();
    }
  }

  render() {
    return (
      <div {...className(['slideshow', this.state.isFullscreen && 'is-fullscreen'])} onKeyDown={ this.handleKeyDown }>
        <div className='slideshow-content'>
          <div className='slideshow-media'>
            {_.map(this.props.media, (medium, m) => (
              <div
                {...className(['slideshow-medium', `size-mode-${medium.sizeMode}`, this.state.index === m && 'active'])}
                key={m}
                style={{backgroundImage: `url(${medium.src})`}}
              >
                <img src={medium.src} alt={medium.title}/>
              </div>
            ))}
          </div>
          <div className='slideshow-controls'>
            <div className='slideshow-prev slideshow-control-button' onClick={this.prev}>
              <div className='arrow'></div>
            </div>
            <div className='slideshow-dots'>
              {_.map(this.props.media, (medium, m) => (
                <div key={m} {...className(['slideshow-dot-container', this.state.index === m && 'active'])} data-index={m} onClick={this.handleDotClick}>
                  <div key={m} {...className(['slideshow-dot', this.state.index === m && 'active'])} data-index={m} onClick={this.handleDotClick}></div>
                </div>
              ))}
            </div>
            <div className='slideshow-next slideshow-control-button' onClick={this.next}>
              <div className='arrow'></div>
            </div>
            <div className='slideshow-toggle-fullscreen slideshow-control-button' onClick={this.toggleFullscreen}>
              <img src={`https://s3.amazonaws.com/spacetheory-public/website/icons/white/${this.state.isFullscreen ? 'exit' : 'enter'}-fullscreen.png`} alt='Toggle fullscreen'/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Slideshow;
