import {React} from 'components';
import {link, colors} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'careers',
  title: 'Careers',
  sections: [
    {
      layout: 'accordionWithMedia',
      heightAuto: true,
      defaultMedia: [{orientation: 'square', src: link('careers/careers-1.jpg')}],
      defaultExpandedSectionIndex: 0,
      accordionTitle: 'CAREERS',
      accordionDescription: {render: () => (
        <div>
          Space Theory  is a multi-disciplinary, design-driven company. We are looking for ambitious, talented and dedicated people to join us - people who want to work together to do amazing things for our clients. <br/><br/>
          If you have proven experience related to the positions below and are interested in finding out more, submit your resume, portfolio (for design positions), and cover letter to the email in the job description below. Or if you have a general inquiry you can email <a className='accordion-text-item-link blue' href='mailto:careers@spacetheory.com' target='_blank'>careers@spacetheory.com.</a>
        </div>)
      },
      accordionSections: [
         {
            title: 'CURRENT OPENINGS',
            text: {
              items: [
                {render: () => (
                  <>
                    <a className='accordion-text-item-link dark-gray' href='https://spacetheory-public.s3.amazonaws.com/website/files/space-theory-studio-manager-bay-area.pdf' target='_blank'>Studio Manager, Bay Area</a>
                    <p>Space Theory, a cutting-edge kitchen design company and a part of the Henrybuilt family, is looking for a dynamic Studio Manager based in the Bay Area. This role involves spearheading the representation of our brand at our new Mill Valley studio, ensuring a premium client experience, and actively engaging with the design community. Join us in shaping the future of kitchen design.</p>
                  </>
                )},
                {render: () => (
                  <>
                    <a className='accordion-text-item-link dark-gray' href='https://spacetheory-public.s3.amazonaws.com/website/files/space-theory-senior-design-sales-leader-bay-area.pdf' target='_blank'>Senior Design & Sales Leader, Bay Area</a>
                    <p>Space Theory, Henrybuilt’s next-gen performance kitchen system company, is seeking an experienced designer with the skill to lead the growth of Space Theory’s business in the Bay Area.  This is an exciting opportunity to join a growing company at an inflection point - a company with a distinctive combination of tech, architecture and design at its core.</p>
                  </>
                )},
                // `We do not currently have any openings. Check back soon!`
              ]
            },
            media: [{orientation: 'square', src: link('careers/careers-1.jpg')}]
         },
       ]
    }
  ]
}
