import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import axios from 'axios';

// var className = (names) => {
//   return {className: _.join(_.filter(names, name => !!name), ' ')};
// };

import {Component, className} from 'henrybuilt-react-library';

var addOnClickOutside = require('react-click-outside');

export {React, Component, PropTypes, _, className, addOnClickOutside, axios};
