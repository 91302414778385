import {_, React, Component, className} from 'components';
import {Link} from 'react-router-dom';

import './landing-page.scss';

export default class LandingPage extends Component {
  static defaultProps = {
    firstTickSpeed: 3500,
    tickFrequency: 3500,
    numberOfImages: 12
  };

  constructor(props) {
    super(props);

    this.state = {tick: 0};
  }

  componentDidMount() {
    this.addStrucuredData();

    this.setTimeout(this.setInterval(this.incrementTick.bind(this), this.props.tickFrequency), this.props.firstTickSpeed);

    // this.props.considerShowingSubscribePopup({pageKey: 'landing'});
    this.props.considerShowingInquiryPopup({pageKey: 'landing'});
  }

  componentWillUnmount() {
    this.setState({tick: 0});

    super.componentWillUnmount();
  }

  componentDidUpdate() {
    // this.props.considerShowingSubscribePopup({pageKey: 'landing'});
    this.props.considerShowingInquiryPopup({pageKey: 'landing'});
  }

  addStrucuredData() {
    var script = document.createElement('script');

    script.setAttribute('type', 'application/ld+json');
    script.innerHTML = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://www.spacetheory.com",
      "sameAs": ["https://www.facebook.com/spacetheorykitchens/", "https://www.instagram.com/space.theory/"],
      "logo": "https://spacetheory-public.s3.amazonaws.com/images/full-logo.jpg",
  	  "image": "https://spacetheory-public.s3.amazonaws.com/images/full-logo.jpg",
      "name": "Space Theory",
      "description": "An intelligent kitchen system from the founders of Henrybuilt. Made to order in Seattle for homes throughout North America.",
      "email": "seattle@spacetheory.com",
      "telephone": "+1-206-454-3160",
      "address": {
        "@type": "postalAddress",
        "streetAddress": "4632 Ohio Ave S",
        "addressLocality": "Seattle",
        "addressCountry": "WA",
        "addressRegion": "United States",
        "postalCode": "98134"
      }
    });

    document.querySelector('head').appendChild(script);
  }

  incrementTick() {
    var tick = this.state.tick + 1;
    var indexOfLastImage = this.props.numberOfImages - 1;

    if (tick > indexOfLastImage) tick = 0;

    this.setState({tick});
  }

  render() {
    var {tick} = this.state;
    var styleFor = (condition) => ({style: {opacity: condition ? 1 : 0}});

    return (
      <div className='landing-page'>
        <div className='main-content-container'>
          <div {...className(['landing-page-screen', 'text'])}>
            <div className='company-name'>
              <span>SPACE</span><span>.THEORY</span>
            </div>
            <div className='tag-line'>KITCHENS</div>
            {/* <div className='links-container'>
              <Link className='landing-page-link' to={`/page/seattle`}>Seattle</Link>
              <div style={{marginLeft: '1rem', marginRight: '1rem', borderBottom: '1px solid #bbb'}}/>
              <Link className='landing-page-link' to={`/page/los-angeles`}>Los Angeles</Link>
              <div style={{marginLeft: '1rem', marginRight: '1rem', borderBottom: '1px solid #bbb'}}/>
              <Link className='landing-page-link' to={`/page/new-york`}>New York</Link>
            </div> */}
            <div className='secondary-tag-line'>
              <a className='landing-page-link' href='https://henrybuilt.com' target='_blank'>FROM THE CREATORS OF HENRYBUILT</a>
            </div>
          </div>
          <div {...className(['landing-page-screen', 'slideshow-container'])}>
            {_.times(this.props.numberOfImages, index => {
              return (
                <div className='landing-page-image-container' key={index} {...styleFor(tick === index)}>
                  <div className='landing-image' style={{backgroundImage: `url(https://spacetheory-public.s3.amazonaws.com/website/pages/landing/set-4/${index + 1}.webp)`}}></div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className='promo-content-container'>
          <div className='showroom-link-container'>
            <div className='showroom-link'>Click Here to Vist Us in Los Angeles</div>
          </div>
          <div className='showroom-link-container'>
            <div className='showroom-link'>New York Coming Soon</div>
          </div>
        </div> */}
      </div>
    );
  }
}
