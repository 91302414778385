import {_, React, Component, className} from 'components'; //eslint-disable-line
import { Link } from 'react-router-dom';
import ExMachinaInquiryForm from 'components/app/ex-machina-inquiry-form/ex-machina-inquiry-form';
import ListItem from './list-item/list-item';
import {Form} from 'henrybuilt-react-library';
import pages from 'pages';
import './navigation.scss';

export default class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {contactFormType: this.props.contactFormType};

    this.hideInquiryForm = () => this.toggleInquiryForm(false);

    this.bind(['handleListItemClick', 'toggleNavigation', 'handleContactTypeInputChange']);
  }

  handleListItemClick({page}) {
    if (page.key === 'contact') {
      this.toggleInquiryForm(true);
    }
  }

  toggleInquiryForm(inquiryFormIsVisible) {
    this.trigger('toggleInquiryForm', {inquiryFormIsVisible});
  }

  toggleNavigation() {
    this.props.toggleNavigation();
  }

  handleContactTypeInputChange({value}) {
    this.props.setContactFormType({value});
  }

  render() {
    var inquiryFormSrc = `/inquiry-form`;
    var {contactFormType} = this.props;
    if (this.props.contactReason) inquiryFormSrc += `?contactReason=${this.props.contactReason}`;

    this.showExMachinaInquiryForm = true;

    return (
      <div
        {...className([
          'app-navigation',
          this.props.isVisible && 'is-visible',
          this.inquiryFormIsVisible && 'inquiry-form-is-visible'
        ])}
      >
        <div className='app-navigation-icons-container'>
          <div {...className(['navigation-icon-container', this.props.isVisible ? 'close' : 'hamburger'])} onClick={this.toggleNavigation}>
            <img src={`https://s3.amazonaws.com/spacetheory-public/website/icons/black/${this.props.isVisible ? 'close' : 'hamburger'}.png`} alt='Toggle menu'/>
          </div>
          <Link className='home-icon-container' to={'/'}>
            <img src={`https://s3.amazonaws.com/spacetheory-public/website/icons/black/home.png`} alt='Homepage'/>
          </Link>
          <div className='discuss-a-project-container' onClick={this.props.showInquiryForm}>
            <div className='discuss-a-project-text'>Discuss a Project</div>
          </div>
        </div>
        <div className='app-navigation-content'>
          <div className='inquiry-form-container'>
            {/* <div className='hide-inquiry-form' onClick={this.hideInquiryForm}>←</div> */}
            <div className='contact-type-input-container'>
              <Form.RadioInput
                {...className(['contact-type-input', 'inline-radio-input'])}
                on={{change: this.handleContactTypeInputChange}}
                value={contactFormType}
                options={[
                  {title: 'Call or Email', value: 'callOrEmail'},
                  {title: 'Contact Form', value: 'contactForm'},
                  // {title: 'Showrooms', value: 'showrooms'}
                ]
              }/>
            </div>
            {
              contactFormType === 'callOrEmail' ? (
                <div className='ex-machina-inquiry-form-container'>
                  <ExMachinaInquiryForm inquiryFormIsVisible={this.inquiryFormIsVisible}/>
                </div>
              ) : contactFormType === 'contactForm' ? (
                <div className='inquiry-form-iframe-container'>
                  <iframe className='inquiry-form-iframe' title='Contact' src={inquiryFormSrc}/>
                </div>
              ) : contactFormType === 'showrooms' ? (
                <div className='showrooms-info-container'>
                  <div className='info-block'>
                    Our headquarters and first showroom are in the Georgetown area of Seattle. If you would like to make an appointment, please call us at 206.406.6145 or email us at <a href="mailto:inquiries@spacetheory.com">inquiries@spacetheory.com</a>.
                  </div>
                  <div className='info-block'>
                    <b>Seattle</b><br/>
                    By appointment<br/>
                    4632 Ohio Ave. S<br/>
                    Seattle, WA 98134<br/>
                    tel: 206.454.3160<br/>
                  </div>
                  <div className='info-block'>
                    <b>New York City</b><br/>
                    By appointment<br/>
                    355 West Broadway<br/>
                    New York, NY 10013<br/>
                    tel: 646.454.9696<br/>
                  </div>
                  <div className='info-block'>
                    <b>Los Angeles</b><br/>
                    By appointment<br/>
                    806 Mateo St<br/>
                    Los Angeles, CA 90021<br/>
                    tel: 310.299.2441<br/>
                  </div>
                </div>
              ) : ''
            }
          </div>
          <div className='navigation-tray'></div>
          <div className='navigation-list'>
            {_.map(pages.tree, (page, p) => (
              <ListItem
                index={p}
                key={p}
                page={page}
                match={this.props.match}
                on={{click: this.handleListItemClick}}
                navigationIsVisible={this.props.isVisible}
              />
            ))}
            <div className='instagram-link'>
              <a href='https://www.instagram.com/space.theory/' target='_blank' rel='noopener noreferrer'>
                <img src='https://s3-us-west-2.amazonaws.com/henrybuilt-cdn/images/logo/instagram.png' alt=''/>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  get inquiryFormIsVisible() {
    return this.props.inquiryFormIsVisible && this.props.isVisible;
  }
}
